import { Grid } from '@mui/material';
import OpinionCard from './OpinionCard';
import { useMobile } from '../../hooks/useMobile';

const opinions = [
  {
    author: 'Ваня и Иво Беевски',
    initials: 'ВБ',
    text: `Прекрасна детска книжка с много моменти за размисъл и за възрастните! Нора е част от щастливо семейство, но независимо от това тя търси кристали, които да направят света по-добър. Тя излиза от уюта на топлия и изпълнен с любов дом и се впуска в търсене на щастие за другите. И не не е сладникаво, по-скоро те кара да се замислиш ти днес какво направи, за да помогнеш на някой друг…Революционен момент е и благословията на майка й, когато разбира, къде се е запътила дъщеричката й. – „Добре, скъпа, желая ти късмет!“. Можем много да се поучим ние родителите.
          Препятствията пред Нора са описани така майсторски, че те оживяват пред вас. И във всяко едно намирате по нещо непреходно в героинята, нещо, благодарение на което светът още съществува, в постоянния му стремеж да е едно по-добро място!
          Нямам търпение за следващото произведение на Стефи!`,
  },
  {
    author: 'Дамянка Стефанова',
    initials: 'ДС',
    text: 'Книгата ме връща много, много назад в годините,когато още като малка обичах да чета странни, фантастични истории. Това продължава и досега.Браво,Стефи! С тази приказка ме върна в моето детство!',
  },
  {
    author: 'Диана Рашкова',
    initials: 'ДР',
    text: 'Интересна и увлекателна история ❤ Прекрасен подарък за всяко дете 🥰',
  },
  {
    author: 'Мариета Петрова',
    initials: 'MП',
    text: 'Книжката е прекрасна и уникална по рода си! За първи път чета книга написана от дете! Радвам се,че я имам и се гордея,че познавам това невероятно дете!',
  },
  {
    author: 'Мариета Николова',
    initials: 'MН',
    text: 'Страхотна книжка,също като авторката…🤗🤗😉',
  },
  {
    author: 'Десислава Иванова',
    initials: 'ДИ',
    text: 'Благодарим за чудесната книжка! 🙂',
  },
  {
    author: 'Явор Цанев',
    initials: 'ЯЦ',
    text: 'Писането е забавно – като игра – и виждам, че Стефи го е разбрала. Не се съмнявам, че се забавлява, докато пише – това личи в цялата история от „Осмото кралство“, която се надявам, че е само първата от многото вълнуващи книги, които тя ще напише, а ние ще прочетем.',
  },
];

function Opinions() {
  const isMobile = useMobile();
  return (
    <Grid
      container
      item
      direction="column"
      sx={{
        flexWrap: 'nowrap',
        //background: 'white',
        height: 'fit-content',
        borderRadius: 5,
      }}
      px={isMobile ? 3 : 0}
    >
      {opinions.map((opinion, i) => {
        return <OpinionCard key={i} opinion={opinion} />;
      })}
    </Grid>
  );
}

export default Opinions;
